/**
 * Given some primitive value (string, number, boolean, null, undefined)
 *  returns the FIRST key as string that matches with the given value
 * @param  {object} object any given object
 * @param  {string|number|boolean|null|undefined} value value of the key you are looking for
 * @returns {string} key name as string of the first matched value
 */
export const getKeyByValue = (object, value) => {
  return Object.keys(object).find((key) => object[key] === value);
};

import { Loader } from '@123-front/ui-kit';
import PropTypes from 'prop-types';
import React, { Suspense, lazy, useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { connect } from 'react-redux';
import { useLocation } from 'react-router-dom';
import i123n from '../../i18n';
import { getStore } from '../../store';
import { domainResolver } from '../../utils/domain-resolver';
import { defaultFlux, detectActiveFlux, getFluxIndex } from '../../utils/fluxes';
import { getKeyByValue } from '../../utils/object';
import { useBaseUrl } from '../../utils/useBaseUrl';
import { useFluxObj } from '../../utils/useFluxObj';
import { analyticsPushEvent, analyticsPushPage } from '../analytics/store/analytics.actions';
import { LoadingPage } from '../main/components/LoadingPage';
import history from '../main/store/history';
import Service from '../service';
import {
  changeFlux,
  getProductStepsConfig,
  moveToNextStep,
  resetProgress,
} from './store/wizard.actions';
import { getModuleName, getStepsList, useActiveFluxSteps } from './store/wizard.store.utils';
import { wizardReducerPropTypes } from './wizard.proptypes';

const Wizard = ({
  product,
  getProductStepsConfig,
  moveToNextStep,
  resetProgress,
  changeFlux,
  wizardState,
  analyticsPushEvent,
  analyticsPushPage,
}) => {
  const store = getStore();
  useTranslation();
  const routeNames = i123n.getDataByLanguage(domainResolver()).translation[product].routeNames;
  const pathName = useLocation().pathname;
  const currentUrl = pathName.split('/');
  const currentPage = currentUrl[currentUrl.length - 1];
  const flux = useFluxObj(detectActiveFlux());
  const componentName = getKeyByValue(routeNames.pages, currentPage);
  const baseUrl = useBaseUrl();
  const activeFluxSteps = useActiveFluxSteps();
  const [injectedProduct, setInjectedProduct] = useState(false);
  const [initializedStore, setInitializedStore] = useState([
    'main',
    'wizard',
    'person',
    'contact',
    'analytics',
    'service',
    product,
  ]);
  const setProductStore = useCallback(async () => {
    const productStore = await import(`../${product}/${product}.reducer`).then(
      (moduleStore) => moduleStore.default,
    );
    store.injectReducer(product, productStore);
    setInjectedProduct(true);
  }, [product, store]);

  useEffect(() => {
    setProductStore();
  }, [setProductStore]);

  const handleNextStep = () => {
    const nextComponentName = getStepsList(activeFluxSteps)[wizardState.completedStep + 1];
    moveToNextStep(`./${routeNames.pages[nextComponentName]}${window.location.search}`);
  };

  const handleChangeFlux = (toFlux, toStep = 0) => {
    changeFlux(baseUrl, toFlux, toStep);
  };

  const handleGetFluxIndex = (fluxName) => {
    return getFluxIndex(wizardState.fluxes, fluxName);
  };
  const loadComponent = (getModuleName) => {
    const Component = lazy(async () => {
      const moduleName = getModuleName(activeFluxSteps, componentName);
      return import(`../${moduleName}/${componentName}`)
        .then(async (module) => {
          if (!initializedStore.includes(moduleName)) {
            const moduleStore = await import(`../${moduleName}/${moduleName}.reducer`).then(
              (moduleStore) => moduleStore.default,
            );
            store.injectReducer(moduleName, moduleStore);
            setInitializedStore([...initializedStore, moduleName]);
          }
          return module;
        })
        .catch((e) => console.error('Error loading component', e));
    });

    return (
      <Service
        handleNextStep={handleNextStep}
        handleChangeFlux={handleChangeFlux}
        handleGetFluxIndex={handleGetFluxIndex}
        ComponentToRender={Component}
        componentName={componentName}
      ></Service>
    );
  };

  useEffect(() => {
    if (wizardState.product !== product) {
      getProductStepsConfig(product);
    }
    if (wizardState.fluxes.length) {
      const fluxesKeys = wizardState.fluxes.map((flux) => flux.index);
      let activeFlux = detectActiveFlux();
      const existsFlux = fluxesKeys.includes(activeFlux);

      if (!existsFlux) {
        analyticsPushEvent(
          'trackError',
          'wizard not found',
          'error-campo',
          { url: pathName },
          'cotizador',
        );

        activeFlux = defaultFlux;
      }
      const stepsList = getStepsList(activeFluxSteps);
      const transStepsList = stepsList.map((step) => routeNames.pages[step]);
      const stepIdx = stepsList.indexOf(componentName);
      if (stepIdx === -1) {
        // step not found or empty
        if (
          !!existsFlux &&
          ![`${baseUrl}/${activeFlux}`, `${baseUrl}/${activeFlux}/`].includes(pathName)
        ) {
          analyticsPushEvent(
            'trackError',
            'wizard not found',
            'error-campo',
            { url: pathName },
            'cotizador',
          );
        }
        resetProgress();
        history.push(
          `${baseUrl}/${activeFlux}/${transStepsList[0] || ''}${window.location.search}`,
        );
      } else if (stepIdx > wizardState.completedStep && !wizardState.hasChangeFlux) {
        if (flux.isPartnerFlux) {
          history.push(`${baseUrl}/${activeFlux}/${window.location.search}`);
        } else {
          history.push(
            `${baseUrl}/${activeFlux}/${transStepsList[wizardState.currentStep - 1]}${
              window.location.search
            }`,
          );
        }
      } else if (stepIdx < wizardState.completedStep) {
        // step behind
        analyticsPushEvent('trackEvent', `back-page-${currentPage}`, 'cotizacion', {
          url: pathName,
        });
        analyticsPushPage(product, componentName);
        resetProgress(stepIdx);
      } else if (stepIdx === wizardState.completedStep) {
        // this is the right step
        if (
          (wizardState.hasChangeFlux && wizardState.activeFlux === flux.index) ||
          !wizardState.hasChangeFlux
        ) {
          analyticsPushPage(product, componentName);
        }
      } else if (wizardState.hasChangeFlux) {
        resetProgress(stepIdx);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    product,
    getProductStepsConfig,
    resetProgress,
    changeFlux,
    analyticsPushEvent,
    analyticsPushPage,
    pathName,
    componentName,
    routeNames,
    wizardState.steps,
  ]);
  return (
    <section>
      {injectedProduct && wizardState.steps && wizardState.steps.length > 0 ? (
        <Suspense fallback={<LoadingPage />}>{loadComponent(getModuleName)}</Suspense>
      ) : (
        <Loader className={'loading'} />
      )}
    </section>
  );
};
Wizard.propTypes = {
  product: PropTypes.string,
  getProductStepsConfig: PropTypes.func,
  moveToNextStep: PropTypes.func,
  resetProgress: PropTypes.func,
  changeFlux: PropTypes.func,
  wizardState: wizardReducerPropTypes,
  analyticsPushEvent: PropTypes.func,
  analyticsPushPage: PropTypes.func,
};

const mapStateToProps = (state) => ({
  wizardState: state.wizard,
  partnerConfig: state.service.partnerConfig.data,
});

export default connect(mapStateToProps, {
  getProductStepsConfig,
  moveToNextStep,
  resetProgress,
  changeFlux,
  analyticsPushEvent,
  analyticsPushPage,
})(Wizard);
